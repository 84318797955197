<template>
  <div>
    <KTCard ref="preview">
      <template v-slot:title>
        <h3 class="card-label">
          <router-link :to="{ name: 'list-subcontractor-expenses' }">{{
            $t("MENU.ITEM.EXP.SUB_EXPENSES")
          }}</router-link>
          <i class="mr-2"></i>
          <small class="">{{ $t("LABELS.EDIT") }}</small>
        </h3>
      </template>
      <template v-slot:toolbar>
        <div class="example-tools justify-content-center">
          <BackButton></BackButton>
          <SaveButtonDropdown
            :defaultAction="'continue'"
            :isLoading="isLoadingSucontractorExpense"
            v-on:save="onSave($event)"
          ></SaveButtonDropdown>
        </div>
      </template>
      <template v-slot:body>
        <SubcontractorExpensesForm
          v-if="!loading"
          ref="SubcontractorExpensesForm"
          :form="form"
        ></SubcontractorExpensesForm>
      </template>
      <template v-slot:foot>
        <div
          v-show="_.size(errors) > 0"
          role="alert"
          class="alert alert-danger"
        >
          <div class="alert-text" v-for="(error, i) in errors" :key="i">
            {{ error }}
          </div>
        </div>
      </template>
      <template v-slot:toolbarfoot>
        <div class="example-tools justify-content-center">
          <BackButton></BackButton>
          <SaveButtonDropdown
            :defaultAction="'continue'"
            :isLoading="isLoadingSucontractorExpense"
            v-on:save="onSave($event)"
          ></SaveButtonDropdown>
        </div>
      </template>
    </KTCard>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import {
  UPDATE_SUBCONTRACTORS_EXPENSE,
  FETCH_SUBCONTRACTORS_EXPENSE,
} from "@/modules/subcontractor-expenses/store/subcontractor-expenses.module";
import SaveButtonDropdown from "@/view/content/forms/components/SaveButtonDropdown.vue";
import KTCard from "@/view/content/Card.vue";
import SubcontractorExpensesForm from "./SubcontractorExpensesForm.vue";

export default {
  name: "SubcontractorExpensesEdit",
  components: {
    KTCard,
    SaveButtonDropdown,
    SubcontractorExpensesForm,
  },
  data() {
    return {
      errors: [],
      loading: true,
      form: {
        invoiceNumber: null,
        invoiceDate: null,
        subcontractorId: null,
        invDateFrom: null,
        invDateTo: null,
        qty: 1,
        totalNoVat: "0.00",
        totalWithVat: "0.00",
        vat: "0.00",
        bolWork: [],
      },
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: this.$i18n.t("MENU.ITEM.EXP.SUB_EXPENSES"),
        route: { name: "list-subcontractor-expenses" },
      },
      { title: this.$i18n.t("LABELS.ADD_NEW") },
    ]);

    this.$store
      .dispatch(FETCH_SUBCONTRACTORS_EXPENSE, this.$route.params.id)
      .then((data) => {
        this.form = data.data;
        this.loading = false;
      });
  },
  methods: {
    onSave(next) {
      this.$refs.SubcontractorExpensesForm.$v.form.$touch();
      if (this.$refs.SubcontractorExpensesForm.$v.form.$anyError) {
        this.$notify({
          group: "notify",
          type: "error",
          title: "<i class='flaticon-close'></i> Warning",
          text: "Please fill all required fields correctly",
        });
        return;
      }
      this.$store
        .dispatch(UPDATE_SUBCONTRACTORS_EXPENSE, {
          id: this.$route.params.id,
          payload: this.form,
        })
        .then((data) => {
          this.errors = data.data.errors;
          this.$notify({
            group: "notify",
            type: "success",
            title: "<i class='flaticon2-checkmark'></i> Success",
            text: data.message,
          });

          if (next == "continue") {
          }
          if (next == "new") {
            //New
            this.$router.push({ name: "add-subcontractor-expenses" });
          }
          if (next == "exit") {
            this.$router.push({ name: "list-subcontractor-expenses" });
          }
        })
        .catch((response) => {
          this.errors = this.$errors.format(response.data.errors);
          this.$notify({
            group: "notify",
            type: "error",
            title: this.$t("ALERTS.ERROR"),
            text: response.data.message,
          });
        });
    },
  },
};
</script>
