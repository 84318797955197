var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"mt-5"},[_c('v-card-title',[_vm._v(" Избор на дейности към товарителници "),_c('v-spacer'),_c('FormInput',{staticStyle:{"flex":"1"},attrs:{"type":"text","i18n":{
        label: 'LABELS.SEARCH',
        placeholder: _vm.$t('LABELS.SEARCH'),
      },"button":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('div',{staticClass:"d-flex p-5"},[_c('v-spacer'),_c('div',[_c('table',[_c('tr',[_c('td',{staticClass:"text-left pr-2"},[_vm._v("Филтрирани к-во:")]),_c('td',{staticClass:"text-right"},[_c('b',[_vm._v(_vm._s(_vm.filteredQty))])])]),_c('tr',[_c('td',{staticClass:"text-left pr-2"},[_vm._v("Филтрирани сума:")]),_c('td',{staticClass:"text-right text-success"},[_vm._v(" "+_vm._s(_vm.filteredSum)+" ")])]),_c('v-divider'),_c('tr',[_c('td',{staticClass:"text-left pr-2"},[_vm._v("Общo к-во:")]),_c('td',{staticClass:"text-right"},[_c('b',[_vm._v(_vm._s(_vm.totalQty))])])]),_c('tr',[_c('td',{staticClass:"text-left pr-2"},[_vm._v("Обща сума:")]),_c('td',{staticClass:"text-right text-success"},[_vm._v(" "+_vm._s(_vm.totalSum)+" ")])])],1)])],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.filteredItems,"loading":_vm.loading,"search":_vm.search,"sort-by":"dateWorked","items-per-page":-1,"item-key":"id","show-select":"","footer-props":{
      itemsPerPageOptions: [20, 50, 100, -1],
    }},scopedSlots:_vm._u([_vm._l((_vm.filters),function(col,i){return {key:("header." + i),fn:function(ref){
    var header = ref.header;
return [_c('div',{key:i,staticClass:"d-flex align-center"},[_vm._v(" "+_vm._s(header.text)+" "),_c('v-menu',{attrs:{"offset-y":"","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":"","color":_vm.filters[i].length > 0 && (_vm.filters[i][0] || _vm.filters[i][1])
                    ? 'primary'
                    : ''}},[_vm._v(" mdi-filter ")])],1)]}}],null,true)},[_c('v-card',{attrs:{"width":"300","height":i === 'dateWorked' ? 520 : null}},[_c('v-card-text',[(i === 'dateWorked')?_c('div',[_c('FormDatepicker',{attrs:{"id":"filter-date-from","type":"text","name":"filter-date-from","menu-class":"filter-datepicker","boundary":"window","i18n":{ label: '', placeholder: 'Дата от' }},model:{value:(_vm.filters[i][0]),callback:function ($$v) {_vm.$set(_vm.filters[i], 0, $$v)},expression:"filters[i][0]"}}),_c('FormDatepicker',{attrs:{"id":"filter-date-to","type":"text","name":"filter-date-to","menu-class":"filter-datepicker","boundary":"window","i18n":{ label: '', placeholder: 'Дата до' }},model:{value:(_vm.filters[i][1]),callback:function ($$v) {_vm.$set(_vm.filters[i], 1, $$v)},expression:"filters[i][1]"}})],1):_c('v-autocomplete',{attrs:{"hide-details":"","label":"Търсене...","dense":"","clearable":"","append-icon":"","outlined":"","multiple":"","items":_vm.getFilteredItems(i),"autocomplete":"off"},model:{value:(_vm.filters[i]),callback:function ($$v) {_vm.$set(_vm.filters, i, $$v)},expression:"filters[i]"}})],1)],1)],1)],1)]}}}),{key:"item.dateWorked",fn:function(ref){
                    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moment")(item.dateWorked,"DD.MM.YYYY"))+" ")]}},{key:"item.machine",fn:function(ref){
                    var item = ref.item;
return [(item.billOfLading.rentedMachine)?_c('span',[_c('div',{staticClass:"mr-2"},[_vm._v(" "+_vm._s(item.billOfLading.rentedMachine.registrationPlate)+" ")]),_c('div',{staticClass:"mr-2"},[_vm._v(" "+_vm._s(item.billOfLading.rentedMachine.machineType.name)+" ")]),_c('div',{staticClass:"mr-2"},[_vm._v(" "+_vm._s(item.billOfLading.rentedMachine.machineModel)+" ")]),_c('div',[_vm._v(_vm._s(item.billOfLading.rentedMachine.machineName))])]):_vm._e()]}},{key:"item.price",fn:function(ref){
                    var item = ref.item;
return [_c('FormInput',{staticClass:"min-w-80",attrs:{"type":"number","value":item[_vm.priceKey]},on:{"input":function($event){return _vm.handlePriceInput(item.id, $event)}}})]}},{key:"item.total",fn:function(ref){
                    var item = ref.item;
return [_vm._v(" "+_vm._s((item[_vm.priceKey] * item.serviceQty).toFixed(2))+" ")]}}],null,true),model:{value:(_vm.selectedBolWork),callback:function ($$v) {_vm.selectedBolWork=$$v},expression:"selectedBolWork"}}),_c('div',{staticClass:"d-flex p-5"},[_c('v-spacer'),_c('div',[_c('table',[_c('tr',[_c('td',{staticClass:"text-left pr-2"},[_vm._v("Филтрирани к-во:")]),_c('td',{staticClass:"text-right"},[_c('b',[_vm._v(_vm._s(_vm.filteredQty))])])]),_c('tr',[_c('td',{staticClass:"text-left pr-2"},[_vm._v("Филтрирани сума:")]),_c('td',{staticClass:"text-right text-success"},[_vm._v(" "+_vm._s(_vm.filteredSum)+" ")])]),_c('v-divider'),_c('tr',[_c('td',{staticClass:"text-left pr-2"},[_vm._v("Общo к-во:")]),_c('td',{staticClass:"text-right"},[_c('b',[_vm._v(_vm._s(_vm.totalQty))])])]),_c('tr',[_c('td',{staticClass:"text-left pr-2"},[_vm._v("Обща сума:")]),_c('td',{staticClass:"text-right text-success"},[_vm._v(" "+_vm._s(_vm.totalSum)+" ")])])],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }