<template>
  <v-card class="mt-5">
    <v-card-title>
      Избор на дейности към товарителници
      <v-spacer></v-spacer>
      <FormInput
        style="flex: 1"
        type="text"
        :i18n="{
          label: 'LABELS.SEARCH',
          placeholder: $t('LABELS.SEARCH'),
        }"
        v-model="search"
        button
      >
      </FormInput>
    </v-card-title>
    <div class="d-flex p-5">
      <v-spacer></v-spacer>
      <div>
        <table>
          <tr>
            <td class="text-left pr-2">Филтрирани к-во:</td>
            <td class="text-right">
              <b>{{ filteredQty }}</b>
            </td>
          </tr>
          <tr>
            <td class="text-left pr-2">Филтрирани сума:</td>
            <td class="text-right text-success">
              {{ filteredSum }}
            </td>
          </tr>
          <v-divider></v-divider>
          <tr>
            <td class="text-left pr-2">Общo к-во:</td>
            <td class="text-right">
              <b>{{ totalQty }}</b>
            </td>
          </tr>
          <tr>
            <td class="text-left pr-2">Обща сума:</td>
            <td class="text-right text-success">
              {{ totalSum }}
            </td>
          </tr>
        </table>
      </div>
    </div>
    <v-data-table
      :headers="headers"
      :items="filteredItems"
      :loading="loading"
      :search="search"
      sort-by="dateWorked"
      v-model="selectedBolWork"
      :items-per-page="-1"
      item-key="id"
      show-select
      :footer-props="{
        itemsPerPageOptions: [20, 50, 100, -1],
      }"
    >
      <template v-for="(col, i) in filters" v-slot:[`header.${i}`]="{ header }">
        <div class="d-flex align-center" :key="i">
          {{ header.text }}
          <v-menu offset-y :close-on-content-click="false">
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on">
                <v-icon
                  small
                  :color="
                    filters[i].length > 0 && (filters[i][0] || filters[i][1])
                      ? 'primary'
                      : ''
                  "
                >
                  mdi-filter
                </v-icon>
              </v-btn>
            </template>
            <v-card width="300" :height="i === 'dateWorked' ? 520 : null">
              <v-card-text>
                <div v-if="i === 'dateWorked'">
                  <FormDatepicker
                    id="filter-date-from"
                    type="text"
                    name="filter-date-from"
                    menu-class="filter-datepicker"
                    boundary="window"
                    :i18n="{ label: '', placeholder: 'Дата от' }"
                    v-model="filters[i][0]"
                  ></FormDatepicker>
                  <FormDatepicker
                    id="filter-date-to"
                    type="text"
                    name="filter-date-to"
                    menu-class="filter-datepicker"
                    boundary="window"
                    :i18n="{ label: '', placeholder: 'Дата до' }"
                    v-model="filters[i][1]"
                  ></FormDatepicker>
                </div>
                <v-autocomplete
                  v-else
                  hide-details
                  label="Търсене..."
                  dense
                  clearable
                  append-icon
                  outlined
                  multiple
                  :items="getFilteredItems(i)"
                  v-model="filters[i]"
                  autocomplete="off"
                ></v-autocomplete>
              </v-card-text>
            </v-card>
          </v-menu>
        </div>
      </template>

      <template v-slot:[`item.dateWorked`]="{ item }">
        {{ item.dateWorked | moment("DD.MM.YYYY") }}
      </template>

      <template v-slot:[`item.machine`]="{ item }">
        <span v-if="item.billOfLading.rentedMachine">
          <div class="mr-2">
            {{ item.billOfLading.rentedMachine.registrationPlate }}
          </div>
          <div class="mr-2">
            {{ item.billOfLading.rentedMachine.machineType.name }}
          </div>
          <div class="mr-2">
            {{ item.billOfLading.rentedMachine.machineModel }}
          </div>
          <div>{{ item.billOfLading.rentedMachine.machineName }}</div>
        </span>
      </template>

      <template v-slot:[`item.price`]="{ item }">
        <FormInput
          class="min-w-80"
          type="number"
          @input="handlePriceInput(item.id, $event)"
          :value="item[priceKey]"
        ></FormInput>
      </template>

      <template v-slot:[`item.total`]="{ item }">
        {{ (item[priceKey] * item.serviceQty).toFixed(2) }}
      </template>
    </v-data-table>
    <div class="d-flex p-5">
      <v-spacer></v-spacer>
      <div>
        <table>
          <tr>
            <td class="text-left pr-2">Филтрирани к-во:</td>
            <td class="text-right">
              <b>{{ filteredQty }}</b>
            </td>
          </tr>
          <tr>
            <td class="text-left pr-2">Филтрирани сума:</td>
            <td class="text-right text-success">
              {{ filteredSum }}
            </td>
          </tr>
          <v-divider></v-divider>
          <tr>
            <td class="text-left pr-2">Общo к-во:</td>
            <td class="text-right">
              <b>{{ totalQty }}</b>
            </td>
          </tr>
          <tr>
            <td class="text-left pr-2">Обща сума:</td>
            <td class="text-right text-success">
              {{ totalSum }}
            </td>
          </tr>
        </table>
      </div>
    </div>
  </v-card>
</template>

<script>
import FormInput from "@/view/content/forms/components/FormInput.vue";
import FormSelect from "@/view/content/forms/components/FormSelect.vue";
import FormDatepicker from "@/view/content/forms/components/FormDatepicker.vue";

export default {
  name: "BillOfLadingTable",
  components: {
    FormInput,
    FormSelect,
    FormDatepicker,
  },
  props: {
    form: Object,
    items: Array,
    selectedBolWork: Array,
    loading: Boolean,
    priceKey: {
      type: String,
      default: "subcontractorPrice",
    },
  },
  data() {
    return {
      filters: {
        "billOfLading.assignorLocation.locationName": [],
        serviceName: [],
        dateWorked: [null, null],
      },
      search: "",
      totalSum: 0,
      totalQty: 0,
      filteredSum: 0,
      filteredQty: 0,
    };
  },
  computed: {
    headers() {
      let machine = [];
      if (this.priceKey === "subcontractorPrice") {
        machine = [
          {
            text: this.$t("FORM_LABELS.MACHINE"),
            value: "machine",
            sortable: true,
          },
          {
            value: "billOfLading.rentedMachine.registrationPlate",
            align: " d-none",
          },
          {
            value: "billOfLading.rentedMachine.machineModel",
            align: " d-none",
          },
          {
            value: "billOfLading.rentedMachine.machineType.name",
            align: " d-none",
          },
          {
            value: "billOfLading.rentedMachine.machineName",
            align: " d-none",
          },
        ];
      }

      let headers = [
        {
          text: this.$t("FORM_LABELS.LOCATION"),
          sortable: true,
          value: "billOfLading.assignorLocation.locationName",
        },
        ...machine,
        {
          text: this.$t("FORM_LABELS.BILL_NUMBER"),
          sortable: true,
          value: "billOfLading.billNumber",
        },
        {
          text: this.$t("FORM_LABELS.WORK_DATE"),
          value: "dateWorked",
          sortable: true,
        },
        {
          text: this.$t("FORM_LABELS.SERVICE"),
          value: "serviceName",
          sortable: true,
        },
        {
          text: this.$t("FORM_LABELS.QTY"),
          value: "serviceQty",
          sortable: true,
        },
        {
          text: this.$t("FORM_LABELS.MEASURE_NAME"),
          value: "measureName",
          sortable: true,
        },
        {
          text: this.$t("FORM_LABELS.PRICE"),
          value: "price",
          sortable: false,
        },
        {
          text: this.$t("FORM_LABELS.TOTAL"),
          value: "total",
          sortable: false,
        },
      ];

      return headers;
    },
    filteredItems() {
      return this.items.filter((it) => {
        let foundLocation = true;
        let foundService = true;
        let isAfterDate = true;
        let isBeforeDate = true;
        const locFilter = this.filters[
          "billOfLading.assignorLocation.locationName"
        ];
        if (locFilter.length > 0) {
          foundLocation = locFilter.some((f) =>
            f === it.billOfLading.assignorLocation.locationName
          );
        }
        if (this.filters.serviceName.length > 0) {
          foundService = this.filters.serviceName.some((f) =>
            f === it.serviceName
          );
        }
        if (this.filters.dateWorked[0]) {
          isAfterDate = it.dateWorked >= this.filters.dateWorked[0];
        }
        if (this.filters.dateWorked[1]) {
          isBeforeDate = it.dateWorked <= this.filters.dateWorked[1];
        }
        return foundLocation && foundService && isAfterDate && isBeforeDate;
      });
    },
  },
  watch: {
    selectedBolWork: {
      immediate: true,
      handler(value) {
        this.$emit("select", value);
        this.calcTotalSum();
      },
    },
    filters: {
      deep: true,
      handler() {
        this.calcTotalSum();
      },
    },
  },
  methods: {
    handlePriceInput(id, value) {
      this.$emit("price:input", id, value);
      this.calcTotalSum();
    },
    calcTotalSum() {
      let filteredSum = 0;
      let filteredQty = 0;
      let sum = 0;
      let qty = 0;
      let filteredIds = null;
      const locFilter = this.filters[
        "billOfLading.assignorLocation.locationName"
      ];
      if (
        locFilter.length > 0 ||
        this.filters.serviceName.length > 0 ||
        this.filters.dateWorked[0] ||
        this.filters.dateWorked[1]
      ) {
        filteredIds = this.filteredItems.map((fi) => fi.id);
      }

      this.selectedBolWork.forEach((bolw) => {
        sum += bolw.serviceQty * bolw[this.priceKey];
        qty += bolw.serviceQty;
        if (filteredIds && filteredIds.includes(bolw.id)) {
          filteredSum += bolw.serviceQty * bolw[this.priceKey];
          filteredQty += bolw.serviceQty;
        }
      });
      this.totalSum = sum.toFixed(2);
      this.totalQty = qty.toFixed(2);
      if (filteredIds) {
        this.filteredSum = filteredSum.toFixed(2);
        this.filteredQty = filteredQty.toFixed(2);
      } else {
        this.filteredSum = this.totalSum;
        this.filteredQty = this.totalQty;
      }

      this.$emit("price:total", this.totalSum);
    },
    getFilteredItems(i) {
      if (i === "billOfLading.assignorLocation.locationName") {
        return this.items.map(
          (it) => it.billOfLading.assignorLocation.locationName
        );
      } else {
        return this.items.map((it) => it[i]);
      }
    },
  },
};
</script>

<style scoped lang="sass">
.min-w-80
  min-width: 80px

.work-table td,
.work-table th
  padding: 0 15px
</style>
